import React from 'react';
import Section from '../../components/section';
import { PageHero } from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import settginsImg from '../../images/webhook/settings.png';
import webhookImg from '../../images/webhook/webhook.png';
import webhookUrlImg from '../../images/webhook/webhook-url.png';

import chooseWorkflow from '../../images/webhook/choose-workflow.png';
import addNew from '../../images/webhook/add-new.png';
import addWebhookAutomation from '../../images/webhook/add-webhook-automation.png';
import putFirstWebhook from '../../images/webhook/put-first-webhook.png';
import addMore from '../../images/webhook/add-more.png';
import putSecondWebhook from '../../images/webhook/put-second-webhook.png';
import './styles.scss';

const WebhookIntegration = () => {
  return (
    <div className="webhook-main">
      <Layout>
        <PageHero
          title="Webhook integration"
        />
        <Section>
          <div
            className="col-12 d-block mb_20 webhook-doc"
            style={{ maxWidth: '1200px', width: '100%' }}
          >
            <h5>There are two ways to connect webhook:</h5>
            <p className="text-title">Connect webhook URL to your account. Each time a workflow with a Webhook automation runs in Hexomatic the JSON formatted data of the workflow will be sent to your provided webhook URL.</p>
            <h6>To use this type of webhook connection:</h6>
            <ol>
              <li>
                <p>Go into Hexomatic Settings</p>
                <img src={settginsImg} alt="settings" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Select API/Webhook</p>
                <img src={webhookImg} alt="webhook" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Put your webhook URL inside webhook field</p>
                <img src={webhookUrlImg} alt="input" style={{ width: '100%' }} />
              </li>
            </ol>
            <br />
            <p className="text-title">Add webhook connection per workflow. Using Webhook automation connect several webhook URLs in one workflow, and each time the workflow is run at the specified point the JSON formatted data of the workflow will be sent to your provided webhook URLs.</p>
            <h6>To use this type of webhook connection:</h6>
            <ol>
              <li>
                <p>Choose workflow</p>
                <img src={chooseWorkflow} alt="monitoring urls" style={{ width: '100%' }} />
              </li>
              <li>
                <p>Click on add new automation icon</p>
                <img src={addNew} alt="edit" style={{ width: '100%' }} />
              </li>
              <li>
                <p>Search for Webhook automation and select it</p>
                <img src={addWebhookAutomation} alt="input" style={{ width: '100%' }} />
              </li>
              <li>
                <p>Put your webhook URL in webhook input</p>
                <img src={putFirstWebhook} alt="input" style={{ width: '100%' }} />
              </li>
              <li>
                <p>To add more webhook URLs click on "ADD NEW FILED" button</p>
                <img src={addMore} alt="input" style={{ width: '100%' }} />
              </li>
              <li>
                <p>Put your second webhook URL in the second input</p>
                <img src={putSecondWebhook} alt="input" style={{ width: '100%' }} />
              </li>
            </ol>
            <br />
            <h5>JSON formatted Data of the workflow is the following</h5>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'['}</p>
              <p className="mgl-25">{'{'}</p>
              <p className="mgl-50">"[output_key_1]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_2]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_3]": "[output_values]",</p>
              <p className="mgl-25">{'},'}</p>
              <p className="mgl-25">{'{'}</p>
              <p className="mgl-50">"[output_key_1]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_2]": "[output_values]"</p>
              <p className="mgl-25">{'},'}</p>
              <p className="mgl-25">{'{'}</p>
              <p className="mgl-50">"[output_key_1]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_2]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_3]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_4]": "[output_values]",</p>
              <p className="mgl-50">"[output_key_5]": "[output_values]"</p>
              <p className="mgl-25">{'}'}</p>
              <p >{']'}</p>
            </pre>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default WebhookIntegration;
